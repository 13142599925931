import React from "react";

const Map = () => {
  console.log(process.env.REACT_APP_GOOGLE_API_KEY);
  return (
    <div className="">
      <iframe
        className="w-full h-[50vh]"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.8474906124657!2d106.79837357609644!3d-6.15117379383591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f6115a8a2e77%3A0xe11df4cd92e62766!2sMina%20Label!5e0!3m2!1sen!2sid!4v1743178165203!5m2!1sen!2sid"
      ></iframe>
      <iframe
        className="w-full h-[50vh] mt-2"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1983.2930368094746!2d106.81258819839474!3d-6.186109999999984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f682d88d0b59%3A0x6ffd65c03a4122eb!2sBank%20Perkreditan%20Rakyat!5e0!3m2!1sen!2sid!4v1743178129073!5m2!1sen!2sid"
      ></iframe>
    </div>
  );
};

export default Map;
